{
  "name": "pregnant-with-diabetes-next",
  "version": "2.60.0",
  "author": "Soren Juul",
  "description": "An app for Pregnant women with Diabetes",
  "homepage": "https://pregnantwithdiabetes.com/",
  "engines": {
    "node": ">=22 <23",
    "npm": ">=11"
  },
  "type": "module",
  "scripts": {
    "config": "node --import 'data:text/javascript,import { register } from \"node:module\"; import { pathToFileURL } from \"node:url\"; register(\"ts-node/esm\", pathToFileURL(\"./\"));' tools/set-env.ts",
    "update:content": "node --import 'data:text/javascript,import { register } from \"node:module\"; import { pathToFileURL } from \"node:url\"; register(\"ts-node/esm\", pathToFileURL(\"./\"));' tools/update-content.ts && prettier --write src/app/initial-data/article.data.ts",
    "ng": "ng",
    "start": "ng serve --port $PORT",
    "build": "npm run config && ng build --configuration production",
    "build:vercel": "npm run sentry:ci:vercel:release:start && npm run build && npm run sentry:ci:vercel:release:commits && npm run sentry:ci:vercel && npm run sentry:ci:vercel:release:finish && npm run ngsw:rehash",
    "build:appflow": "npm run sentry:ci:appflow:release:start && CI_BUILD_NUMBER=$(($CI_BUILD_NUMBER + 3000000)) npx trapeze run appflow.yml -y --$CI_PLATFORM && npm run build && npm run sentry:ci:appflow:release:commits && npm run sentry:ci:appflow && npm run sentry:ci:appflow:release:finish && npm run ngsw:rehash",
    "test": "ng test",
    "lint": "eslint && ng lint",
    "start:server": "vercel dev",
    "assets": "npx @capacitor/assets generate",
    "sync": "ionic capacitor sync --no-build",
    "sentry:ci:vercel:release:start": "sentry-cli releases new $VERCEL_GITHUB_COMMIT_SHA",
    "sentry:ci:vercel:release:commits": "sentry-cli releases set-commits $VERCEL_GITHUB_COMMIT_SHA --auto --ignore-missing",
    "sentry:ci:vercel:release:finish": "sentry-cli releases finalize $VERCEL_GITHUB_COMMIT_SHA",
    "sentry:ci:appflow:release:start": "sentry-cli releases new $CI_GIT_COMMIT_SHA",
    "sentry:ci:appflow:release:commits": "sentry-cli releases set-commits $CI_GIT_COMMIT_SHA --auto --ignore-missing",
    "sentry:ci:appflow:release:finish": "sentry-cli releases finalize $CI_GIT_COMMIT_SHA",
    "sentry:ci:vercel": "sentry-cli sourcemaps inject --org heyworlddk --project pregnant-app ./www && sentry-cli sourcemaps upload --org heyworlddk --project pregnant-app --release $VERCEL_GITHUB_COMMIT_SHA --dist web ./www",
    "sentry:ci:appflow": "sentry-cli sourcemaps inject --org heyworlddk --project pregnant-app ./www && sentry-cli sourcemaps upload --org heyworlddk --project pregnant-app --release $CI_GIT_COMMIT_SHA --dist $CI_PLATFORM ./www",
    "postinstall": "npm run update:content",
    "checkly:test": "npx checkly test --record",
    "typecheck": "tsc --noEmit",
    "ngsw:rehash": "ngsw-config www/browser ngsw-config.json"
  },
  "private": true,
  "dependencies": {
    "@angular/common": "19.2.4",
    "@angular/core": "19.2.4",
    "@angular/forms": "19.2.4",
    "@angular/platform-browser": "19.2.4",
    "@angular/platform-browser-dynamic": "19.2.4",
    "@angular/router": "19.2.4",
    "@angular/service-worker": "19.2.4",
    "@capacitor-community/firebase-analytics": "7.0.0",
    "@capacitor-community/in-app-review": "7.0.0",
    "@capacitor/android": "7.1.0",
    "@capacitor/app": "7.0.0",
    "@capacitor/core": "7.1.0",
    "@capacitor/haptics": "7.0.0",
    "@capacitor/ios": "7.1.0",
    "@capacitor/keyboard": "7.0.0",
    "@capacitor/splash-screen": "7.0.0",
    "@capacitor/status-bar": "7.0.0",
    "@ionic-native/core": "5.36.0",
    "@ionic/angular": "8.5.2",
    "@ionic/core": "8.5.2",
    "@ionic/storage": "4.0.0",
    "@ionic/storage-angular": "4.0.0",
    "@ngx-translate/core": "16.0.4",
    "@ngx-translate/http-loader": "16.0.1",
    "@sentry/angular": "8.42.0",
    "@sentry/capacitor": "1.2.1",
    "@sentry/node": "8.42.0",
    "@vercel/analytics": "1.5.0",
    "@vercel/node": "latest",
    "@vercel/speed-insights": "1.2.0",
    "lodash": "4.17.21",
    "phin": "4.0.0",
    "rxjs": "7.8.2",
    "tslib": "2.8.1",
    "uuid": "11.1.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.2.5",
    "@angular-eslint/builder": "19.3.0",
    "@angular-eslint/eslint-plugin": "19.3.0",
    "@angular-eslint/eslint-plugin-template": "19.3.0",
    "@angular-eslint/schematics": "19.3.0",
    "@angular-eslint/template-parser": "19.3.0",
    "@angular-eslint/utils": "19.3.0",
    "@angular/cli": "19.2.5",
    "@angular/compiler": "19.2.4",
    "@angular/compiler-cli": "19.2.4",
    "@angular/language-service": "19.2.4",
    "@capacitor/cli": "7.1.0",
    "@eslint/eslintrc": "3.3.1",
    "@eslint/js": "9.23.0",
    "@ionic/angular-toolkit": "12.1.1",
    "@ionic/cli": "7.2.1",
    "@playwright/test": "1.51.1",
    "@sentry/cli": "2.43.0",
    "@trapezedev/configure": "7.1.3",
    "@types/jasmine": "5.1.7",
    "@types/node": "22.13.14",
    "@types/uuid": "10.0.0",
    "@typescript-eslint/eslint-plugin": "8.28.0",
    "@typescript-eslint/parser": "8.28.0",
    "@typescript-eslint/utils": "8.28.0",
    "ajv": "8.17.1",
    "checkly": "latest",
    "eslint": "9.23.0",
    "eslint-config-prettier": "10.1.1",
    "eslint-plugin-prettier": "5.2.5",
    "ionicons": "7.4.0",
    "jasmine-core": "5.6.0",
    "jasmine-spec-reporter": "7.0.0",
    "karma": "6.4.4",
    "karma-chrome-launcher": "3.2.0",
    "karma-coverage-istanbul-reporter": "3.0.3",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "native-run": "2.0.1",
    "prettier": "3.5.3",
    "ts-node": "10.9.2",
    "typescript": "5.6.3",
    "vercel": "41.4.1"
  }
}
